import React from 'react'
import { useRef } from 'react';
import { Link } from "react-router-dom";
import "./shop.css"
import IMG1 from "../../Assets/shop/eggholder.jpg"
import IMG2 from "../../Assets/shop/easterbunny1.jpg"
import IMG3 from "../../Assets/shop/set2.jpg"
import IMG4 from "../../Assets/shop/Vase1.jpg"
import IMG5 from "../../Assets/shop/Vase2.jpg"
import IMG6 from "../../Assets/shop/easterbunny2.jpg"
import IMG7 from "../../Assets/shop/easteregg1.jpg"

const data = [
  {
    id:1,
    image: IMG3,
    title: 'Decorative vase set "Luma"',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1892517097/decorative-vase-set-luma-3d-print-eco',
    price: '€ 29.90'
  },
  {
    id:2,
    image: IMG4,
    title: 'Decorative vase "Formo"',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1892147223/decorative-vase-3d-print-formo-up-to-22',
    price: '€ 13.90'
  },
  {
    id:3,
    image: IMG5,
    title: 'Decorative vase "Vero"',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1891683857/eco-friendly-decorative-vase-3d-printed',
    price: '€ 12.90'
  },
  {
    id:4,
    image: IMG1,
    title: '3D Gedruckter Eierhalter Osterdeko',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1880337488/3d-gedruckter-eierhalter-osterdeko-hasen',
    price: '€ 3.90'
  },
  {
    id:5,
    image: IMG2,
    title: 'Geriffelte Häschen Deko',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1880258700/geriffelte-haschen-deko-aus-recycletem',
    price: '€ 4.90'
  },
  {
    id:5,
    image: IMG6,
    title: 'SüßeHäschen Deko',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1882037146/susse-haschen-deko-aus-recycletem-pla',
    price: '€ 4.90'
  },
  {
    id:5,
    image: IMG7,
    title: 'Geriffelte Ostereier Häschen',
    website: "Order",
    link: 'https://3dvaseformstudio.etsy.com/de-en/listing/1896233797/geriffelte-ostereier-haschen-deko-aus',
    price: '€ 4.90'
  },
]

const Shop = () => {
  const containerRef = useRef(null);

  const scrollLeft = () => {
    containerRef.current.scrollBy({
      left: -200, // Adjust the scroll distance
      behavior: 'smooth', // Smooth scrolling effect
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      left: 200, // Adjust the scroll distance
      behavior: 'smooth', // Smooth scrolling effect
    });
  };

  return (
    <section id="portfolio">
      <h5>3DVaseFormStudio</h5>
      <h2>Our Shop</h2>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Left Arrow Button */}
        <button onClick={scrollLeft} className="scroll-button">←</button>

        {/* Scrollable Container */}
        <div
          ref={containerRef}
          className="container portfolio__container"
          style={{
            display: 'flex',
            overflowX: 'auto', // Allows horizontal scrolling
            gap: '1rem', // Spacing between items
            scrollBehavior: 'smooth', // Smooth scrolling
          }}
        >
          {data.slice().reverse().map(({ id, image, title, website, link, price }) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  {website !== "" ? (
                    <a href={link} className="btn">{website}</a>
                  ) : null}
                  <Link className="btn btn-primary">{price}</Link>
                </div>
              </article>
            );
          })}
        </div>

        {/* Right Arrow Button */}
        <button onClick={scrollRight} className="scroll-button">→</button>
      </div>
    </section>
  );
};




export default Shop
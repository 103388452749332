import React from 'react'
import "./nav.css"
import {AiOutlineHome , AiOutlineUser} from "react-icons/ai"
import {BiBookAlt , BiMessageSquareDetail} from "react-icons/bi"
import {RiServiceLine} from "react-icons/ri"
import { useState } from 'react'
import { FaShoppingCart } from "react-icons/fa";
import { SiMaterialdesignicons } from "react-icons/si";
import { TbBrandFiverr } from "react-icons/tb";


const Nav = () => {
  const[activeNav,setActiveNav] = useState("#")

  return (
    <nav className='navi'>
      <a href="#" onClick={() => setActiveNav("#")} className={activeNav === "#" ? "active" : ""}><AiOutlineHome/></a>
      <a href="https://3dvaseformstudio.etsy.com" target="_blank" className={activeNav === "#about" ? "active" : ""}><FaShoppingCart/></a>
      <a href="https://www.fiverr.com/s/e65g51k" onClick={() => setActiveNav("#services")} className={activeNav === "#services" ? "active" : ""}><TbBrandFiverr /></a>
      <a href="#3dinteriordesign" onClick={() => setActiveNav("#3dinteriordesign")} className={activeNav === "/#3dinteriordesign" ? "active" : ""}><SiMaterialdesignicons /></a>
    </nav>
  )
}

export default Nav